
:root {
    --crt-width: 600px;
}

#crt-container {
    --CRT-padding: 40px;

    position: relative;
    padding: var(--CRT-padding);
    width: calc(100% - var(--CRT-padding) * 2);
    max-width: 600px;
    min-width: 301px;
}

#crt-container * {
    user-select: none;
}

#crt-border {
    width: 100%;   
    position: relative;
    pointer-events: none;
}

.crt-internal {
    position: absolute;
    
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    mask-image: url(../res/minigames/crt-mask.png);
    mask-repeat: no-repeat;
    mask-size: calc(100% - var(--CRT-padding) * 2) calc(100% - var(--CRT-padding) * 2);
    mask-position: center;
}

#crt-static {
    background-image: url(../res/minigames/static.gif);
    background-position: top left;
    background-size: 200px;
    pointer-events: none;
}

#crt-shadow {
    background-image: radial-gradient(at 50% 45%, transparent 0%, black 70%);
    pointer-events: none;
}

#crt-screen-door-rows {
    background-image:
    repeating-linear-gradient(to bottom,
    white 0px, white 1px,
    #ccc 1px, #ccc 2px);
    opacity: 10%;
    animation: row-flicker 100ms infinite;
    pointer-events: none;
}

#crt-screen-door-cols {
    background-image:
    repeating-linear-gradient(to right,
    red 8px, green 16px, blue 24px);
    opacity: 5%;
    pointer-events: none;
}

#crt-scan-bar {
    background-image: linear-gradient(to bottom, blue 0, blue 15%, transparent 15%, transparent 100%);
    animation: scan-bar 600ms infinite;
    pointer-events: none;
}

#crt-text {
    --_pad: calc(clamp(301px * 0.12, 0.12*var(--crt-width), 680px * 0.12) + 40px);
    /* 680px is width breakpoint */
    padding: var(--_pad);
    width: calc(100% - var(--_pad) * 2);
    height: calc(100% - var(--_pad) * 2);


    color: #3cd500;
    -webkit-text-stroke: 1px black;
    /* z-index: 1;
    background-color: red; */
    font-family: 'CRT', 'Courier New', Courier, monospace;
    font-size: calc(var(--_pad) - 40px);
    text-align: right;
    pointer-events: none;

}

#crt-content {
    /* z-index: 1; */
    --_pad: max(301px * 0.10, min(10vw, calc(680px * 0.10)));
    /* 680px is width breakpoint */
    padding: var(--_pad);
    width: calc(100% - var(--_pad) * 2);
    height: calc(100% - var(--_pad) * 2);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    /* transition: background-color 100ms linear; */
}

@keyframes row-flicker {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(3px);
    }

    100% {
        transform: translateY(0px);
    }
}


@keyframes scan-bar {
    0% {
        opacity: 0.2;
        transform: rotateX(0deg);
    }
    100% {
        opacity: 0;
        transform: rotateX(360deg);
    }
}