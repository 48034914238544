@font-face {
    font-family: 'SF Pro Display';
    src: url('../res/fonts/SF-Pro-Display/SF-Pro-Display-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('../res/fonts/SF-Pro-Display/SF-Pro-Display-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('../res/fonts/SF-Pro-Display/SF-Pro-Display-Semibold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('../res/fonts/SF-Pro-Display/SF-Pro-Display-Heavy.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'CRT';
    src: url('../res/fonts/game_over.otf') format('opentype');
}