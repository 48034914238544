:root {
    --transition: 0.5s ease-out;
}

.header-link {
    transition: color var(--transition);
    text-decoration: none;
    text-align: center;
    padding-block: 10px;
    display: flex;
    position: relative;
    justify-content: center;
}

#home-favicon {
    position: absolute;
    top: 4px;
    width: 60px;
    height: 20px;
    background-color: black;
    padding: 0 10px 10px 5px;
    pointer-events: none;
    font-size: 12px;
    text-align: start;
    transition: top var(--transition);
}

.header-link.hide-favicon #home-favicon {
    top: -30px;
}

.header-menu {
    padding-block: 10px;
    transition: color var(--transition);
    text-decoration: none;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}

.header-menu-container {
    position: relative;
    display: flex;
    justify-content: center;
}

.header-menu-dropdown {
    pointer-events: none;
    opacity: 0;
    position: absolute;
    isolation: isolate;
    top: 100%;
    left: 50%;
    display: flex;
    flex-direction: column;
    height: fit-content;
    z-index: 2;
    background-color: var(--accent-gray);
    padding-inline: 15px;
    translate: -50%;
    /* gap: 10px; */
    border-radius: 20px;
    box-shadow: 0px 4px 4px 4px #00000040;

    transition: opacity 100ms linear;
}

.header-menu-dropdown .header-link {
    transition: color var(--transition);

}

.header-menu-dropdown .header-link:hover {
    color: var(--secondary);

}

.header-menu-dropdown-trigger-booster {
    position: absolute;
    top: 0;
    left: 50%;
    translate: -50%;
    z-index: -1;
    width: 100%;
    height: 100%;
    padding-inline: 20px;
}

.header-menu-container:hover .header-menu-dropdown-trigger-booster,
.header-menu-dropdown-trigger-booster:hover {
    padding-bottom: 20px;
}

.header-menu-container:hover .header-menu-dropdown,
.header-menu-dropdown:hover,
.header-menu-dropdown-trigger-booster:hover + .header-menu-dropdown {
    pointer-events: all;
    opacity: 1;
}

.header-menu-arrow {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


#header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    position: relative;
    top: 0;
    height: 40px;

    box-shadow: 0px 0px 100px 10px #ffffff10;
}

#header-container {
    position: fixed !important;
    width: 100%;
    top: 0;
    /* overflow: hidden; */
    z-index: 12 !important;
}

#header-line-back {
    width: 10px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: left var(--transition), width var(--transition);
    background-color: white;
    z-index: 3;
}

#header-line {
    position: absolute;
    bottom: 4px;
    left: 0;
    width: 10px;
    z-index: 1;
    height: 2px;
    background-color: white;
    transition: left var(--transition), width var(--transition);
}

.header-size-big {
    & *:is(#functions-header-item, #minigames-header-item) {
        display: flex;
    }

    & *:is(#more-header-item, #header-menu-container-more-header-item) {
        display: none;
    }
}

.header-size-small {
    & *:is(#functions-header-item, #minigames-header-item) {
        display: none;
    }

    & *:is(#more-header-item, #header-menu-container-more-header-item) {
        display: flex;
    }
}