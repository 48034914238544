#base {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

body {
    overflow-y: hidden;
}

:root {
    scrollbar-width: none;
    --accent-gray: #161616;
}

::-webkit-scrollbar {
    display: none;
}