
* {
  margin: 0;
  padding: 0;
  -ms-overflow-style: none;
  font-family: BlinkMacSystemFont, 'SF Pro Display', sans-serif;

}

a, a:visited, a:hover, a:active {
  color: white;
}

body {
  font-family: BlinkMacSystemFont, 'SF Pro Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--accent-gray);
  
}

.display-none {
  display: none !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}