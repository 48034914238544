:root {
    /* --project-height: 440px; */
    --project-width: 240px;
    --project-gap: 100px;

    --PB-wide-height: 100vh;
    --PB-wide-top: calc(100vh + 40px - var(--scroll-y));

    --PB-narrow-height: 100vh;
    --PB-narrow-top: calc(100vh + 80px - var(--scroll-y));
}

#projects-section {
    /* max-height: calc(100vh - 40px); */
    min-height: calc(100vh - 40px);
    isolation: isolate;
    position: relative;
    z-index: 10;
    overflow-y: hidden;
}



.page-title {
    font-size: 50px;
    height: 60px;
    margin-left: 40px;
    padding-block: 5px;
}

#projects-back {
    width: 100vw;
    height: var(--PB-wide-height);
    top: var(--PB-wide-top);
    right: 0px;
    background-color: white;
    position: fixed;
    z-index: 9;
    /* opacity: .5; */
    clip-path: polygon(100% 0px, 100% 100% , 0% 100%, 0% 195px, 50% 195px, 50% 0px);
}


#projects-content {
    position: relative;
    min-height: calc(100vh - 165px);
    /* max-height: calc(100vh - 165px);
    min-height: calc(100vh - 165px); */
    padding-bottom: 55px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

#projects-container {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: var(--project-gap);
    flex-wrap: nowrap;
    width: 50vw;
    overflow-x: scroll;
    overflow-y: hidden;
    max-height: 100%;
    min-height: calc(var(--project-width) * 440 / 240);
    padding: 70px 0 40px 0;
    scroll-snap-type: x mandatory;
}





.project-container:first-of-type {
    /* margin-left: 50%; */
}

.project-container:last-of-type {
    /* margin-right: 50%; */
}

.projects-container-spacing {
    width: var(--project-width);
}

.projects-container-spacing:first-of-type {
    padding-left: 50%;
}

.projects-container-spacing:last-of-type {
    padding-right: 50%;
}

.project-container {
    position: relative;
    scroll-snap-align: center;
    translate: -50% 0%;
    min-width: var(--project-width);
    /* height: var(--project-height); */
    aspect-ratio: 240 / 440;
}

.project {
    --rotate-x: 0deg;
    --rotate-y: 0deg;

    width: var(--project-width);
    /* height: var(--project-height); */
    aspect-ratio: 240 / 440;
    color: white;
    /* background-color: var(--primary); */
    border-radius: 20px;
    transform: perspective(500px)
        rotateX(var(--rotate-x))
        rotateY(var(--rotate-y));
    opacity: 1;
    transition: background-color var(--color-transition-time) ease,
    transform 300ms ease-out, scale 600ms ease, opacity 300ms ease;

    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.project-icon-container {
    position: absolute;
    top: calc(-70px + 30px); /* had to take out the translate switching */
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    transition: top 300ms ease,
    width 300ms ease, height 300ms ease;
}

.project-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.project-container:not(.current-project-container)>.project {
    scale: 0.3;
    opacity: 0;
}

.project-container:not(.current-project-container)>.project-icon-container {
    top: 50%;
    width: 80px;
    height: 80px;
}


#project-info {
    width: calc(50vw - 40px);
    min-width: 290px;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
    padding: 50px 0 0 40px;
    color: white;
}

#project-title {
    font-size: 30px;
}

#project-subtitle {
    color: var(--secondary);
    transition: color var(--color-transition-time) ease;
}

#project-description {
    font-weight: 400;
    height: calc(var(--project-width) * 440 / 240 - 70px);
    overflow-y: scroll;
}


#project-description>a {
    color: var(--secondary);
    /* text-decoration: solid; */
    font-weight: 600;
    transition: color var(--color-transition-time) ease;
}

.projects-content-button {
    top: calc(70px + var(--project-width) * 440 / 240 / 2);
}

#projects-content.more-left>#left-button, #projects-content.more-right>#right-button {
    width: 40px;
    height: 40px;
    position: absolute;
    transform: translate(-50%, -50%);
    background-position-y: 50%;
    background-size: 50%;
    background-repeat: no-repeat;
    z-index: 10;
    cursor: pointer;
    border-radius: 50%;
    backdrop-filter: blur(10px);
    background-color: #ffffff18;
}

/*  */

#projects-content.more-right>#right-button {
    background-position-x: 60%;
    left: calc(75% + (var(--project-width) + var(--project-gap) / 2) / 2);
    /* left: calc(50% + (var(--project-width) + var(--project-gap)) / 2); */
    background-image: url('../res/rounded-chevron-right.png');
}

#projects-content.more-left>#left-button {
    background-position-x: 40%;
    left: calc(75% - (var(--project-width) + var(--project-gap) / 2) / 2);
    /* left: calc(50% - (var(--project-width) + var(--project-gap)) / 2); */
    background-image: url('../res/rounded-chevron-left.png');
}



@media (max-width: 655px) {
    #projects-section {
        height: fit-content;

    }
    #projects-content {
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: center;
        height: fit-content;
    }

    #projects-back {
        top: var(--PB-narrow-top);
        height: var(--PB-narrow-height);
        z-index: 9;
        /* opacity: .5; */
        --clip-in-top: calc(70px + 40px + 290px);
        --clip-in-bottom: calc(var(--clip-in-top) + 33px);

        clip-path: polygon(0% 70px, 230px 70px, 230px 0%, 100% 0%,
         100% 100%, 0% 100%, 0% var(--clip-in-bottom),
         100% var(--clip-in-bottom), 100% var(--clip-in-top),
         0% var(--clip-in-top));
    }

    :root {
        --project-width: 120px;
        --project-gap: 50px;
    }

    .project {
        border-radius: 10px;
    }

    #projects-container {
        width: 100%;
    }

    #project-info {
        height: 290px;
        max-height: 290px;
        padding-top: 0;
        padding-inline: 0;
        gap: 20px;
    }

    #project-description {
        font-size: 16px;
        overflow-y: scroll;
        height: 200px;
        height: max-content;
    }

    #project-title {
        font-size: 28px;
        text-align: center;
        max-height: 33px;
    }
    
    #project-subtitle {
        text-align: center;
    }

    #projects-content.more-left>#left-button, #projects-content.more-right>#right-button {
        top: calc((330px + 35px) / 2);
    }
    
    #projects-content.more-right>#right-button {
        left: calc(50% + (var(--project-width) + var(--project-gap)) / 2);
    }
    
    #projects-content.more-left>#left-button {
        left: calc(50% - (var(--project-width) + var(--project-gap)) / 2);
    }
}