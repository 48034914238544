.form-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#contact-form {
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    width: calc(100% - 100px);
    max-width: 550px;
    gap: 20px;
    padding: 40px;
}

#contact-message {
    resize: vertical;
}

.form-item {
    padding: 10px 20px 20px 20px;
    background-color: var(--accent-gray);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.form-item.long {
    grid-column: 1/3;
}

.form-item>*:last-child {
    width: calc(100% - 20px);
    /* border-radius: 10px; */
    padding: 4px 8px;
    color: white;
    border: none;

}

.form-item>input:last-child:not([type="submit"]) {
    background-color: var(--accent-gray);
    border-bottom: 1px solid white;
}

.form-item>select:last-child {
    background-color: #000;
    border-radius: 10px;
    width: 100%;
}

.form-item>textarea:last-child {
    background-color: var(--accent-gray);
    border-bottom: 1px solid white;
    height: 16.5px;
}

#contact-submit {
    width: 100px;
    height: 36px;
    color: var(--accent-gray);
    font-size: 20px;
    border-radius: 10px;
    background-color: var(--secondary);
    font-weight: 600;
    cursor: pointer;
    transition: background-color var(--transition);
}

#contact-submit:hover {
    background-color: var(--primary);
}

.form-item.long.mid {
    background-color: transparent;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.form-item label {
    color: var(--secondary);
    transition: color var(--transition);
}

#honeypot {
    position: absolute;
    left: -1000px;
}

@media (max-width: 414px) {
    .page-title {
        font-size: 40px;
        width: fit-content;
        height: max-content;
    }
}

@media (max-width: 495px) {
    .form-item>*:last-child:not(textarea) {
        font-size: 11px;
    }
}