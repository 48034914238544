#functions-main {
    color: white;
}

#functions-header {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 40px;
}

#functions-content {
    margin-left: 40px;
    padding-block: 5px;
}

#functions-description {
    display: flex;
    position: relative;
    flex-direction: column;
    z-index: 10;
    width: 300px;
    padding: 20px;
    background-color: var(--accent-gray);
    border-radius: 20px;
    margin: 20px 40px 0 40px;
    box-shadow: 0px 4px 4px 4px #00000040;

}

#function-items-container {
    display: flex;
    width: calc(100% - 40px);
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
}

.function-item {
    padding: 20px;
    background-color: var(--accent-gray);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 80px);
    max-width: 1000px;
    max-height: 146px;
    overflow: hidden;
}

.function-item-execute-button {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-inline: 20px;
}

.function-item-execute-button-image {
    cursor: pointer;
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.function-item-execute-button.has-input {
    top: 10px;
}


.function-item-input {
    display: block;
    /* position: absolute; */
    border-radius: 10px;
    min-height: 24px;
    width: calc(100% - 20px);
    padding-inline: 8px;
    border: none;
    background-color: white;
    transition: background-color 200ms;
}

.function-item-input.reject-shake {
    background-color: #ff6161ce;
    color: white;
    animation: reject-shake 200ms ease-in-out infinite;
}

.function-item-details {
    display: flex;
    flex-direction: column;
}

.function-item-title {
    font-weight: 700;
    font-size: 24px;
    color: var(--secondary);
    transition: color var(--transition);
    margin-bottom: 5px;
}

.function-item-description {
    width: 200px;
    max-height: 120px;
    overflow-y: scroll;
}

.function-cloud {
    position: relative;
    width: 200px;
    height: fit-content;
    scale: 1;
    top: 0px;
    left: 0px;
    transition: top 400ms ease, left 400ms ease;
    display: flex;
    flex-direction: column;
    gap: 40px;
    translate: 0 calc((120px + 40px) / 2 + 10px);
}

.function-cloud.function-cloud-show-result {
    top: calc((-140px - 40px));
}


.function-cloud .cloud-img {
    width: 200px;
    height: 140px;
    object-fit: contain;
    object-position: center;
}

.function-cloud .function-cloud-used-images-container {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    z-index: 2;
    display: flex;
    flex-direction: row;
    gap: 10px;
    transition: opacity var(--transition)
}

.function-cloud-used-image {
    width: 30px;
    height: 30px;
    border-radius: 8px;
}

.function-cloud-main.cloud-pulsing .function-cloud-used-images-container {
    opacity: 0.3;
}

.function-cloud-main {
    position: relative;
}

.function-cloud-main.cloud-pulsing {
    animation: cloud-pulsing 2000ms ease-in-out infinite;
}

.function-cloud-loader {
    position: absolute;
    translate: -50% -50%;
    top: 50%;
    left: 50%;
    color: black;
    width: 30px;
    height: 30px;
    animation: loader-spinning 4000ms linear infinite;
}

.function-cloud-result {
    width: 180px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    color: var(--secondary);
    transition: color var(--transition);
    min-height: 120px;
    max-height: 120px;
    overflow-y: scroll;
    word-break: break-all;
    border-radius: 10px;
    padding: 10px 14px;
    background-color: black;
}

.function-cloud-result.small-text {
    font-size: 12px;
}

.function-cloud-result {
    & table{
        border-collapse: collapse;
    }

    & *:is(td, th) {
        border: 1px solid white;
        padding: 2px;
    }

    & th {
        width: 56px;
    }
}

.function-cloud-result-image-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.function-cloud-result-image {
    width: 100%;
    object-fit: contain;
}

@media (min-width: 689px) {
    #functions-description {
        position: absolute;
        top: 0px;
        right: 0px;
    }

    #functions-header {
        margin-bottom: 80px;
    }
}

@media (max-width: 720px) {
    .function-item {
        flex-direction: column;
        max-height: none;
        max-width: 200px;
    }   
    
    .function-cloud {
        flex-direction: row;
        width: max-content;
        translate: 120px 0;
    }

    .function-item-execute-button {
        margin: 20px 0 10px 0;

        & svg {
            rotate: 90deg;
        }
    }

    .function-item-execute-button.has-input {
        margin: 20px 0 50px 0;
    }

    .function-cloud.function-cloud-show-result {
        top: 0;
        left: -240px;
    }

    #function-items-container {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
    }
}

@media (max-width: 600px) {
    #function-items-container {
        flex-direction: column;
        align-items: center;
    }
}

@keyframes loader-spinning {
    0% {
        rotate: 0deg;
    }

    100% {
        rotate: 360deg;
    }
}

@keyframes cloud-pulsing {
    0% {
        scale: 1;
    }

    50% {
        scale: 1.2;
    }

    100% {
        scale: 1;
    }
}

@keyframes reject-shake {
    0% {
        translate: -10px;
    }

    100% {
        translate: 10px;
    }
}