:root {
    --accent-x: -200px;
    --accent-y: -200px;

    --scroll-x: 0px;
    --scroll-y: 0px;

    --blend-transition: 0s;
}

.cutout {
    background-color: black;
    color: white;
    mix-blend-mode: multiply;
}

.cutout-container {
    /* overflow: hidden; */
}

.cutout-color-accent-container {
    position: absolute;
    inset: 0;
    overflow: hidden;
}

.color-strip {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: var(--primary);
    transition: background-color var(--color-transition-time) ease;
}

.color-accent {
    width: 40px;
    height: 40px;
    position: absolute;
    top: var(--accent-y);
    left: var(--accent-x);
    z-index: 2;
    border-radius: 50%;
    background-color: var(--secondary);
    box-shadow: 0px 0px 80px 80px var(--secondary);
    translate: -50% -50%;
    transition: background-color var(--color-transition-time) ease,
    box-shadow var(--color-transition-time) ease;
}

@media only screen and (max-width: 450px) {
    .color-accent {
        /* transition: background-color var(--color-transition-time) ease,
        box-shadow var(--color-transition-time) ease,
        top 100ms ease, left 100ms ease; */
    }
}

.color-accent.follow-scroll {
    /* top: calc(var(--accent-y) + var(--scroll-y));
    left: calc(var(--accent-x) + var(--scroll-x)); */
}