#home-name > * {
    width: fit-content;
    /* height: max-content; */
    padding-left: 40px;
    display: flex;
    flex-direction: column;
}

#home-name-title {
    margin-left: 17px;
    margin-top: 15px;
    font-size: 24px;
}

#home-name h1 {
    font-size: 80px;
}

.page-main, section.no-container-for-section-page-main {
    height: 100vh;
    color: white;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    background-color: black;
}
section.no-container-for-section-page-main {
    margin-top: 20px;
}

#home {
    /* height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory; */
}

.react-section {
    padding-top: 40px;
    overflow-y: scroll;
    scroll-snap-align: start;
}

#home>section {
    scroll-snap-align: start;
    min-height: 100vh;
    height: fit-content;
}

#home-container {
}


#home-container>.cutout {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 40px);
    justify-content: space-between;
}


#home-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 120px;

}

#scroll-button {
    width: 80px;
    height: 80px;
    cursor: pointer;
}

#scroll-button+h2 {
    text-align: center;
}

#scroll-button>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

#projects-title-cutout-container, #project-title-cutout-container {
    /* overflow: hidden; */
}


@media (max-width: 655px) {
    #home-name h1 {
        font-size: 60px;
    }

    #home-name-title {
        margin-left: 12px;
    }
    
}


