#minigames-section {
    background-color: black;
    width: 100%;
    height: max-content;
    /* min-height: 100vh; */
}

#minigames-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}

#game-iframe {
    position: relative;
    translate: 0 -50%;
    top: calc(40% + 10px);
    border: none !important;
    outline: none !important;
}

#game-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    height: max-content;
}

#minigames-main {
    scroll-snap-type: none;
}

#game-info {
    padding-inline: 40px;
    color: white;
}

#game-info > h3 {
    font-weight: 400;
}

#game-title {
    font-size: 50px;
    font-weight: bold;
    padding-bottom: 20px;
}

#game-description {
    padding-bottom: 10px;
}

#game-controls-heading {
    color: var(--secondary);
    transition: color var(--color-transition-time) ease;
    padding-bottom: 10px;
}

#game-select-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    width: calc(100% - 2 * 40px);
    padding-inline: 40px;
    height: fit-content;
    padding: 100px 0 100px 0;
}

@media (max-width: 799px) {
    #game-container {
        flex-direction: column;
        align-items: center;
    }
}