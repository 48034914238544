.collapsible-wrapper {
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    transition: grid-template-rows 200ms;
}

.collapsible-content {
    display: flex;
    overflow: hidden;
    flex-direction: column;
}

.collapsible-wrapper.open {
    grid-template-rows: 1fr
}